export const AGE_BRACKETS = {
  Fourteen_Seventeen: "14-17",
  Eighteen_TwentyFour: "18-24",
};

export const AGE_BRACKET_OPTIONS = [
  {
    text: "18-24 years old",
    value: AGE_BRACKETS.Eighteen_TwentyFour,
  },
  {
    text: "14-17 years old",
    value: AGE_BRACKETS.Fourteen_Seventeen,
  },
];

export const DEFAULT_ERROR_MESSAGE =
  "An unexpected error occurred, please try again later.";

export const LOCALSTORAGE_KEYS = {
  EOI_FORM_INDIVIDUAL: "eoiIndividual",
  EOI_FORM_GROUP: "eoiGroup",
};

export const SUITABILITIES = {
  WORK_EXPERIENCE: "11",
  YOUNG_ADULTS: "14", // Young adults, 18 - 24
  YOUNGER_VOLUNTEERS: "1", // Younger Volunteers, under 18
};

export const QUERYSTRING = {
  AREAS_OF_INTEREST: "interestAreas",
  CODE: "code",
  ID: "id",
  YOUNGER_VOLUNTEERS_ONLY: "youngerVolunteerOnly",
};
