import React, { useState } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import type { IApiProblemDetails } from "~/types";
import { Routes } from "~/models";
import { Layout, Meta, Hero, Section } from "~/ui";
import { QUERYSTRING } from "~/constants";

enum Status {
  Confirm,
  Loading,
  LinkInvalid,
  NotFound,
  Success,
  Error,
  Cancel,
}

export default function Unsubscribe(): JSX.Element {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get(QUERYSTRING.ID);

  let [result, setResult] = useState<IApiProblemDetails | null>(null);
  let [status, setStatus] = useState(
    !token ? Status.LinkInvalid : Status.Confirm
  );

  const unsubscribe = () => {
    setStatus(Status.Loading);
    const apiUrl = new URL(
      process.env.GATSBY_PUBLIC_ID || "",
      `${process.env.GATSBY_API_URL}/widget`
    );
    const url = new URL(
      `${apiUrl}/unsubscribe/${encodeURIComponent(String(token))}`
    );

    fetch(url, {
      referrerPolicy: "origin-when-cross-origin",
      method: "PUT",
    })
      .then(async (response) => {
        if (response.ok) {
          // var res: IUnsubscribeResponse = await response.json();
          // Consider every request to have been completed successfully
          // setResult(res);
          // setStatus(res.success ? Status.Success : Status.NotFound);
          setStatus(Status.Success);
        } else {
          var pb: IApiProblemDetails = await response.json();
          setResult(pb);
          // TODO: handle the different errors
          setStatus(Status.LinkInvalid);
        }
      })
      .catch((error) => {
        setStatus(Status.Error);
        console.log(error);
      });
  };

  const cancel = () => {
    setStatus(Status.Cancel);
  };

  return (
    <Layout>
      <Hero />
      <Section bgColor="offWhite">
        <h1 className="h2 fw-normal text-success mb-4">
          {status == Status.Cancel ? "Great" : "Update your email preferences"}
        </h1>
        {/* <h1 className="text-primary mb-4">
          <span className="display-2">Unsubscribe</span>
        </h1> */}
        {status == Status.Confirm && (
          <>
            <p className="mb-4">
              Privacy is important, and so is a non-cluttered inbox. Unsubscribe
              by selecting the link below, you can always opt-in another day.
            </p>
            <hr className="mb-4 border-2" />

            {/* <h2 className="h3 mb-4">
              Privacy is important, and so is a non-cluttered inbox. Unsubscribe
              by selecting the link below, you can always opt-in another day.
            </h2> */}
            <button
              type="button"
              className="btn btn-primary me-4"
              onClick={unsubscribe}
            >
              Unsubscribe
            </button>

            <button type="button" className="btn btn-link" onClick={cancel}>
              Actually, keep me in
            </button>
          </>
        )}
        {status == Status.Loading && <Loading />}
        {status == Status.LinkInvalid && <LinkInvalid />}
        {status == Status.NotFound && <NotFound />}
        {status == Status.Error && <Error />}
        {status == Status.Cancel && <Cancel />}
        {status == Status.Success && <Success />}
      </Section>
    </Layout>
  );
}

const Success = () => {
  return (
    <>
      <p className="mb-4">You have been successfully unsubscribed.</p>
      <p className="lead">
        Didn't mean to unsubscribe? <Link to={Routes.SignUp}>resubscribe</Link>
      </p>
    </>
  );
};

const Loading = () => {
  return <p className="mb-4">Hang tight! We're working on it.</p>;
};

const LinkInvalid = () => {
  return (
    <p className="mb-4">We could not unsubscribed you, this link is invalid.</p>
  );
};

const NotFound = () => {
  return (
    <p className="mb-4">
      We could not unsubscribed you, this link might be incorrect.
    </p>
  );
};

const Error = () => {
  return (
    <p className="mb-4">Sorry something not working - It's not you, it's us.</p>
  );
};

const Cancel = () => {
  return <p className="mb-4">Everything is as you had it</p>;
};

export const Head = () => {
  return (
    <Meta
      title="Unsubscribe"
      description="You have been unsubscribed from the mailing list."
    />
  );
};
